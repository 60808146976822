import React from 'react'
import { Link } from 'gatsby'

import LogoColor from 'images/logo.svg'

const SimpleHeader = () => (
  <header className="simple-header">
    <Link to="/">
      <div className="logo">
        <LogoColor />
      </div>
    </Link>
  </header>
)

export default SimpleHeader
