/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react'
import PropTypes from 'prop-types'

import SimpleHeader from 'components/SimpleHeader'
import Footer from 'components/Footer'
import 'styles/main.scss'

const LayoutSimple = ({ children }) => (
  <>
    <SimpleHeader />
    <main className="page is-active signup">{children}</main>
    <Footer />
  </>
)

LayoutSimple.propTypes = {
  children: PropTypes.node.isRequired,
}

export default LayoutSimple
