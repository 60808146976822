import React from 'react'

import LayoutSimple from 'components/LayoutSimple'
import SEO from 'components/SEO'

const Terms = () => (
  <LayoutSimple>
    <SEO title="Terms & Conditions" />
    <div className="terms-container">
      <section>
        <h1>PBJ LIVE PLATFORM USER AGREEMENT</h1>
        <p>
          <b>______________________________________________________________</b>
        </p>
        <p>
          PBJ PROVIDES THE PBJ LIVE PLATFORM SOLELY ON THE TERMS AND CONDITIONS
          SET FORTH IN THIS AGREEMENT AND ON THE CONDITION THAT YOU ACCEPT AND
          COMPLY WITH THEM. BY ACCESSING THE PBJ LIVE PLATFORM, YOU (A) ACCEPT
          THIS AGREEMENT AND AGREE THAT YOU ARE LEGALLY BOUND BY ITS TERMS; AND
          (B) REPRESENT AND WARRANT THAT: (I) YOU ARE OF LEGAL AGE TO ENTER INTO
          A BINDING AGREEMENT; AND (II) IF YOU REPRESENT A CORPORATION,
          GOVERNMENTAL ORGANIZATION, OR OTHER LEGAL ENTITY, YOU HAVE THE RIGHT,
          POWER, AND AUTHORITY TO ENTER INTO THIS AGREEMENT ON BEHALF OF THE
          ENTITY AND BIND IT TO ITS TERMS. IF YOU DO NOT AGREE TO THE TERMS OF
          THIS AGREEMENT, PBJ WILL NOT AND DOES NOT LICENSE THE PBJ LIVE
          PLATFORM TO YOU AND YOU MUST NOT DOWNLOAD THE PBJ LIVE PLATFORM OR
          DOCUMENTATION.
        </p>
      </section>
      <section>
        <ol>
          <li>
            <b>Definitions</b>.
          </li>
          <ol type="a">
            <li>
              &ldquo;
              <u>Aggregated Statistics</u>
              &rdquo; means data and information derived from or related to your
              use of the PBJ Live Platform that is used by PBJ in an aggregate
              and anonymized manner, including to compile statistical and
              performance information related to the provision and operation of
              the PBJ Live Platform.
            </li>
            <li>
              &nbsp;&ldquo;
              <u>Content</u>&rdquo; means, other than Aggregated Statistics,
              information, data, text, images, graphics, photos, video, audio,
              and other content, in any form or medium, that is submitted,
              posted, derived from, acccesible via,&nbsp; or otherwise
              transmitted by or on behalf of you through the PBJ Live
              Platform.&nbsp;
            </li>
            <li>
              &ldquo;
              <u>Documentation</u>
              &rdquo; means PBJ&rsquo;s user manuals, handbooks, and guides
              relating to the PBJ Live Platform provided by PBJ to you either
              electronically or in hard copy form.
            </li>
            <li>
              &ldquo;<u>PBJ</u>&rdquo; means PBJ Apps, LLC, a Delaware limited
              liability company.&nbsp;
            </li>
            <li>
              &ldquo;<u>PBJ IP</u>
              &rdquo; means the PBJ Live Platform, the Documentation, and any
              and all intellectual property embodied in the foregoing or
              otherwise provided to you in connection with the foregoing. For
              the avoidance of doubt, PBJ IP includes Aggregated Statistics and
              any information, data, or other content derived from PBJ&rsquo;s
              monitoring of access to or use of the PBJ Live Platform, but does
              not include Content.
            </li>
            <li>
              &ldquo;
              <u>PBJ Live Platform</u>
              &rdquo; means PBJ&rsquo;s platform offering that is referred
              generally by PBJ as &ldquo;PBJ Live.&rdquo; For the avoidance of
              doubt, the PBJ Live Platform includes such platform as may be
              modified or updated by PBJ.
            </li>
            <li>
              &nbsp;&ldquo;
              <u>Third-Party Products</u>
              &rdquo; means any third-party products or materials provided with
              or incorporated into the PBJ Live Platform.
            </li>
          </ol>
          <li>
            <b>Access and Use of the PBJ Live Platform</b>
            .&nbsp;
          </li>
          <ol type="a">
            <li>
              <b>Provision of Access</b>. Subject to and conditioned on your
              payment of fees and compliance with the terms and conditions of
              this Agreement, PBJ hereby grants you a non-exclusive,
              non-transferable license (except in compliance with Section 10(g))
              to access and use the PBJ Live Platform to stream Content to end
              users during the Term, solely in accordance with the terms and
              conditions herein.&nbsp;
            </li>
            <li>
              <b>Documentation License</b>. Subject to the terms and conditions
              contained in this Agreement, PBJ hereby grants you a
              non-exclusive, non-sublicensable, non-transferable (except in
              compliance with Section 10(g)) license to use the Documentation
              during the Term solely in connection with your authorized use of
              the PBJ Live Platform.
            </li>
            <li>
              <b>Use Restrictions</b>. You shall not use the PBJ Live Platform
              for any purposes beyond the scope of the access granted in this
              Agreement. You shall not at any time, directly or indirectly: (i)
              copy, modify, or create derivative works of the PBJ Live Platform
              or Documentation, in whole or in part; (ii) rent, lease, lend,
              sell, license, sublicense, assign, distribute, publish, transfer,
              or otherwise make available the PBJ Live Platform or
              Documentation, except as expressly authorized in this Agreement;
              (iii) reverse engineer, disassemble, decompile, decode, adapt, or
              otherwise attempt to derive or gain access to any software or code
              component of the PBJ Live Platform, in whole or in part, except as
              expressly authorized in this Agreement; (iv) bypass or breach any
              security device or protection included within the PBJ Live
              Platform; (v) input, upload, transmit, or otherwise provide to or
              through the PBJ Live Platform any information or materials that
              are injurious, infringing, libelous, pornographic, offensive or
              otherwise unlawful or tortious material or data or in violation of
              privacy rights or contain, transmit or activate any malicious
              code, viruses, time bombs, Trojan horses, or similar mechanisms,
              scripts, agents, bots or programs; (vi) damage, destroy, disrupt,
              disable, impair, interfere with, or otherwise impede or harm in
              any manner the PBJ Live Platform; (vii) remove any proprietary
              notices from the PBJ Live Platform or Documentation; or (viii) use
              the PBJ Live Platform or Documentation in any manner or for any
              purpose that infringes, misappropriates, or otherwise violates any
              intellectual property right or other right of any person, or that
              violates any applicable law.&nbsp;
            </li>
            <li>
              <b>Reservation of Rights</b>. PBJ reserves all rights not
              expressly granted to you in this Agreement. Except for the limited
              rights and licenses expressly granted under this Agreement,
              nothing in this Agreement grants, by implication, waiver,
              estoppel, or otherwise, to you or any third party any intellectual
              property rights or other right, title, or interest in or to the
              PBJ IP. Without limiting the foregoing, PBJ reserves the right, in
              its sole discretion, to make any changes to the PBJ IP that it
              deems necessary or useful to maintain or enhance the quality or
              delivery of the PBJ IP to its clients, the competitive strength of
              or market for PBJ&rsquo;s products and services or the PBJ
              IP&rsquo;s cost efficiency or performance, or to comply with
              applicable law.&nbsp;
            </li>
            <li>
              <b>Suspension</b>. Notwithstanding anything to the contrary in
              this Agreement, PBJ may temporarily suspend your access to any
              portion or all of the PBJ IP if: (i) PBJ reasonably determines
              that (A) there is a threat or attack on any of the PBJ IP; (B)
              your use of the PBJ IP disrupts or poses a security risk to the
              PBJ IP or to any other customer or vendor of PBJ; (C) you are
              using the PBJ IP for fraudulent or illegal activities; (D) subject
              to applicable law, and if you are an entity, you have ceased to
              continue your business in the ordinary course, made an assignment
              for the benefit of creditors or similar disposition of your
              assets, or have become the subject of any bankruptcy,
              reorganization, liquidation, dissolution, or similar proceeding;
              or (E) PBJ&rsquo;s provision of the PBJ Live Platform to you is
              prohibited by applicable law; or (ii) any vendor of PBJ has
              suspended or terminated PBJ&rsquo;s access to or use of any
              third-party services or products required to enable you to access
              the PBJ Live Platform (any such suspension described in subclause
              (i) or (ii), a &ldquo;
              <u>Service Suspension</u>
              &rdquo;). PBJ shall use commercially reasonable efforts to provide
              written notice of any Service Suspension to you and to provide
              updates regarding resumption of access to the PBJ IP following any
              Service Suspension. PBJ shall use commercially reasonable efforts
              to resume providing access to the PBJ IP as soon as reasonably
              possible after the event giving rise to the Service Suspension is
              cured. PBJ will have no liability for any damage, liabilities,
              losses (including any loss of data or profits), or any other
              consequences that you may incur as a result of a Service
              Suspension.&nbsp;
            </li>
            <li>
              <b>Aggregated Statistics</b>. Notwithstanding anything to the
              contrary in this Agreement, PBJ may monitor use of the PBJ Live
              Platform and collect and compile Aggregated Statistics. As between
              PBJ and you, all right, title, and interest in Aggregated
              Statistics, and all intellectual property rights therein, belong
              to and are retained solely by PBJ. You acknowledge that PBJ may
              compile Aggregated Statistics based on Content input into the PBJ
              Live Platform. You agree that PBJ may (i) make Aggregated
              Statistics publicly available in compliance with applicable law,
              and (ii) use Aggregated Statistics to the extent and in the manner
              permitted under applicable law.&nbsp;
            </li>
          </ol>
          <li>
            <b>Your Responsibilities</b>.
          </li>
          <ol type="a">
            <li>
              <b>Responsibility of Content</b>. All Content is the sole
              responsibility of the user from which such Content originated. PBJ
              has no responsibility or liability for the deletion or failure to
              store any Content or user data. You are responsible for backing up
              all Content. PBJ may preserve and disclose Content if required to
              do so by law or judicial or governmental mandate or as reasonably
              necessary to protect the rights, property or safety of PBJ, users
              and/or the public. In the event that you elect not to comply with
              a request from PBJ to remove certain Content, PBJ may disable the
              PBJ Live Platform until compliance is secured. PBJ reserves the
              right to disable the PBJ Live Platform in response to a violation
              or suspected violation of this Agreement.
            </li>
            <li>
              <b>Use of Content</b>. You bear all risks associated with the use
              of any Content, including any reliance on the quality, integrity,
              accuracy, completeness, or usefulness of such Content. PBJ may
              refuse or delete any Content, including Content of which PBJ
              becomes aware that fails to fulfill the purpose of the PBJ Live
              Platform, is in breach of this Agreement, is otherwise contrary to
              law, or is otherwise inappropriate in PBJ&rsquo;s discretion. PBJ
              does not guarantee the accuracy, integrity or quality of any
              Content. Under no circumstances is PBJ liable in any way for any
              Content, including, but not limited to, liability for any errors,
              inaccuracies, or omissions in any Content, or for any loss or
              damage of any kind incurred as a result of the use of any Content.
              PBJ has no obligation or liability to maintain, store, or license
              Content, protect and maintain Content owners&rsquo; intellectual
              property rights, or to enforce this Agreement. You hereby waive
              and release any claims you may have against PBJ arising or
              resulting from use or misuse of Content or your inability to
              effectively use Content, your failure to comply with this
              Agreement, or for any act, omission, or conduct of any PBJ Live
              Platform user.
            </li>
            <li>
              <b>Propriety of Content</b>. You will not, and you will ensure
              that a user resulting from access provided by you will not,
              transmit Content or otherwise conduct or participate in any
              activities on or via the PBJ Live Platform which is likely to be
              prohibited by law, or is violative or in breach of third party
              rights in any applicable jurisdiction, including without
              limitation laws governing privacy, publicity, libel and
              defamation, encryption of software, the export of technology, the
              transmission of obscenity or the permissible uses of intellectual
              property. You will not and you will ensure that a user resuting
              from access provided by you will not upload, download, display,
              perform, transmit, or otherwise distribute any Content, or
              otherwise engage in any activity in connection with the PBJ Live
              Platform, that (a) is hateful, offensive, racist, bigoted,
              libelous, defamatory, obscene, abusive, pornographic, lewd,
              erroneous, stalking, or threatening; (b) advocates or encourages
              conduct that could constitute a criminal offense, give rise to
              civil liability, or otherwise violate any applicable local, state,
              national, or foreign law or regulation; (c) constitutes
              infringement of the intellectual property rights of any party,
              including rights to the use of name and likeness, or violation of
              a right of privacy; (d) creates an impression that is incorrect,
              misleading, or deceptive, including without limitation by
              impersonating others or by otherwise misrepresenting identity or
              affiliation with a person or entity; or (e) divulges other
              people&rsquo;s private or personally identifiable information
              without their express authorization and permission. You represent
              and warrant that you have all necessary rights and licenses to
              transmit Content. PBJ may, in its sole discretion, terminate the
              PBJ Live Platform as to any Content that it believes in its sole
              judgment is not in compliance with this Agreement.
            </li>
            <li>
              <b>Third-Party Products</b>. PBJ may from time to time make
              Third-Party Products available to you. For purposes of this
              Agreement, such Third-Party Products are subject to their own
              terms and conditions. If you do not agree to abide by the
              applicable terms for any such Third-Party Products, then you
              should not install or use such Third-Party Products.
            </li>
          </ol>
          <li>
            <b>Fees and Payment</b>. All fees are payable in the manner set
            forth on the subscription page and are non-refundable, except as may
            be expressly set forth herein.&nbsp;
          </li>
          <li>
            <b>Intellectual Property Ownership</b>
            .&nbsp;
          </li>
          <ol type="a">
            <li>
              <b>PBJ IP</b>. You acknowledge that, as between you and PBJ, PBJ
              owns all right, title, and interest, including all intellectual
              property rights, in and to the PBJ IP and, with respect to
              Third-Party Products, the applicable third-party providers own all
              right, title, and interest, including all intellectual property
              rights, in and to the Third-Party Products.&nbsp;
            </li>
            <li>
              <b>Content</b>. PBJ acknowledges that, as between PBJ and you, you
              own all right, title, and interest, including all intellectual
              property rights, in and to the Content. You hereby grant to PBJ a
              non-exclusive, royalty-free, worldwide license to reproduce,
              distribute, and otherwise use and display the Content and perform
              all acts with respect to the Content as may be necessary for PBJ
              to provide the PBJ Live Platform to you, and a non-exclusive,
              perpetual, irrevocable, royalty-free, worldwide license to
              reproduce, distribute, modify, and otherwise use and display
              Content incorporated within the Aggregated Statistics.
            </li>
          </ol>
          <li>
            <b>Warranty Disclaimer</b>.&nbsp;
          </li>
          <ol type="a">
            <li>
              THE PBJ IP IS PROVIDED &ldquo;AS IS&rdquo; AND PBJ HEREBY
              DISCLAIMS ALL WARRANTIES, WHETHER EXPRESS, IMPLIED, STATUTORY, OR
              OTHERWISE. PBJ SPECIFICALLY DISCLAIMS ALL IMPLIED WARRANTIES OF
              MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE, AND
              NON-INFRINGEMENT, AND ALL WARRANTIES ARISING FROM COURSE OF
              DEALING, USAGE, OR TRADE PRACTICE. PBJ MAKES NO WARRANTY OF ANY
              KIND THAT THE PBJ IP, OR ANY PRODUCTS OR RESULTS OF THE USE
              THEREOF, WILL MEET YOUR OR ANY OTHER PERSON&rsquo;S REQUIREMENTS,
              OPERATE WITHOUT INTERRUPTION, ACHIEVE ANY INTENDED RESULT, BE
              COMPATIBLE OR WORK WITH ANY SOFTWARE, SYSTEM, OR OTHER SERVICES,
              OR BE SECURE, ACCURATE, COMPLETE, FREE OF HARMFUL CODE, OR ERROR
              FREE.&nbsp;
            </li>
            <li>
              PBJ STRICTLY DISCLAIMS ALL WARRANTIES, WITH RESPECT TO ANY
              THIRD-PARTY PRODUCTS.
            </li>
          </ol>
          <li>
            <b>Indemnification</b>.&nbsp;
          </li>
          <ol type="a">
            <li>
              <b>PBJ Indemnification</b>.&nbsp;
            </li>
            <ol type="i">
              <li>
                PBJ shall indemnify, defend, and hold you harmless from and
                against any and all losses, damages, liabilities, costs
                (including reasonable attorneys&rsquo; fees) (&ldquo;
                <u>Losses</u>&rdquo;) incurred by you resulting from any
                third-party claim, suit, action, or proceeding (&ldquo;
                <u>Third-Party Claim</u>
                &rdquo;) that the PBJ Live Platform infringes or misappropriates
                such third party&rsquo;s United States intellectual property
                rights, provided that you promptly notify PBJ in writing of the
                claim, cooperate with PBJ, and allow PBJ sole authority to
                control the defense and settlement of such claim.&nbsp;
              </li>
              <li>
                If such a claim is made or appears possible, you agree to permit
                PBJ, at PBJ&rsquo;s sole discretion, to (A) modify or replace
                the PBJ Live Platform, or component or part thereof, to make it
                non-infringing, or (B) obtain the right for you to continue use.
                If PBJ determines that neither alternative is reasonably
                available, PBJ may terminate this Agreement, in its entirety or
                with respect to the affected component or part, effective
                immediately on written notice to you.&nbsp;
              </li>
              <li>
                This Section 7(a) will not apply to the extent that the alleged
                infringement arises from: (A) use of the PBJ Live Platform in
                combination with data, software, hardware, equipment, or
                technology not provided by PBJ or authorized by PBJ in writing;
                (B) modifications to the PBJ Live Platform not made by PBJ; (C)
                Content; or (D) Third-Party Products.&nbsp;
              </li>
            </ol>
            <li>
              <b>Your Indemnification</b>. You shall indemnify, hold harmless,
              and, at PBJ&rsquo;s option, defend PBJ from and against any Losses
              resulting from any Third-Party Claim that the Content, or any use
              of the Content in accordance with this Agreement, infringes or
              misappropriates such third party&rsquo;s United States
              intellectual property rights and any Third-Party Claims based on
              your (i) Content or negligence or willful misconduct; (ii) use of
              the PBJ Live Platform in a manner not authorized by this
              Agreement; (iii) use of the PBJ Live Platform in combination with
              data, software, hardware, equipment, or technology not provided by
              PBJ or authorized by PBJ in writing; or (iv) modifications to the
              PBJ Live Platform not made by PBJ, provided that you may not
              settle any Third-Party Claim against PBJ unless PBJ consents to
              such settlement, and further provided that PBJ will have the
              right, at its option, to defend itself against any such
              Third-Party Claim or to participate in the defense thereof by
              counsel of its own choice.&nbsp;
            </li>
            <li>
              <b>Sole Remedy</b>. THIS SECTION 7 SETS FORTH YOUR SOLE REMEDY AND
              PBJ&rsquo;S SOLE LIABILITY AND OBLIGATION FOR ANY ACTUAL,
              THREATENED, OR ALLEGED CLAIMS THAT THE PBJ LIVE PLATFORM INFRINGE,
              MISAPPROPRIATE, OR OTHERWISE VIOLATE ANY INTELLECTUAL PROPERTY
              RIGHTS OF ANY THIRD PARTY.&nbsp;
            </li>
          </ol>
          <li>
            <b>Limitations of Liability</b>. IN NO EVENT WILL PBJ BE LIABLE
            UNDER OR IN CONNECTION WITH THIS AGREEMENT UNDER ANY LEGAL OR
            EQUITABLE THEORY, INCLUDING BREACH OF CONTRACT, TORT (INCLUDING
            NEGLIGENCE), STRICT LIABILITY, AND OTHERWISE, FOR ANY: (a)
            CONSEQUENTIAL, INCIDENTAL, INDIRECT, EXEMPLARY, SPECIAL, ENHANCED,
            OR PUNITIVE DAMAGES; (b) INCREASED COSTS, DIMINUTION IN VALUE OR
            LOST BUSINESS, PRODUCTION, REVENUES, OR PROFITS; (c) LOSS OF
            GOODWILL OR REPUTATION; (d) USE, INABILITY TO USE, LOSS,
            INTERRUPTION, DELAY, OR RECOVERY OF ANY DATA, OR BREACH OF DATA OR
            SYSTEM SECURITY; OR (e) COST OF REPLACEMENT GOODS OR SERVICES, IN
            EACH CASE REGARDLESS OF WHETHER PBJ WAS ADVISED OF THE POSSIBILITY
            OF SUCH LOSSES OR DAMAGES OR SUCH LOSSES OR DAMAGES WERE OTHERWISE
            FORESEEABLE. IN NO EVENT WILL PBJ&rsquo;S AGGREGATE LIABILITY
            ARISING OUT OF OR RELATED TO THIS AGREEMENT UNDER ANY LEGAL OR
            EQUITABLE THEORY, INCLUDING BREACH OF CONTRACT, TORT (INCLUDING
            NEGLIGENCE), STRICT LIABILITY, AND OTHERWISE EXCEED THE TOTAL
            AMOUNTS PAID TO PBJ UNDER THIS AGREEMENT IN THE THREE-MONTH PERIOD
            PRECEDING THE FIRST EVENT GIVING RISE TO THE CLAIM.&nbsp;
          </li>
          <li>
            <b>Term and Termination</b>.&nbsp;
          </li>
          <ol type="a">
            <li>
              <b>Term</b>. This Agreement and the license granted hereunder
              shall remain in effect for the term set forth on the subscription
              page or until earlier terminated as set forth herein (the &ldquo;
              <u>Term</u>&rdquo;).
            </li>
            <li>
              <b>Termination</b>. In addition to any other express termination
              right set forth in this Agreement:
            </li>
            <ol type="i">
              <li>
                PBJ may terminate this Agreement, effective on written notice to
                you, if you breach any of your obligations under Section 2(c) or
                Section 3(c);
              </li>
              <li>
                either party may terminate this Agreement, effective on written
                notice to the other party, if the other party materially
                breaches this Agreement, and such breach: (A) is incapable of
                cure; or (B) being capable of cure, remains uncured thirty (30)
                days after the non-breaching party provides the breaching party
                with written notice of such breach; or
              </li>
              <li>
                either party may terminate this Agreement, effective immediately
                upon written notice to the other party, if the other party: (A)
                becomes insolvent or is generally unable to pay, or fails to
                pay, its debts as they become due; (B) files or has filed
                against it, a petition for voluntary or involuntary bankruptcy
                or otherwise becomes subject, voluntarily or involuntarily, to
                any proceeding under any domestic or foreign bankruptcy or
                insolvency law; (C) makes or seeks to make a general assignment
                for the benefit of its creditors; or (D) applies for or has
                appointed a receiver, trustee, custodian, or similar agent
                appointed by order of any court of competent jurisdiction to
                take charge of or sell any material portion of its property or
                business.
              </li>
            </ol>
            <li>
              <b>Effect of Expiration or Termination</b>. Upon expiration or
              earlier termination of this Agreement, you shall immediately
              discontinue use of the PBJ IP and you shall delete, destroy, or
              return all copies of the PBJ IP. No expiration or termination will
              affect your obligation to pay all fees that may have become due
              before such expiration or termination.
            </li>
            <li>
              <b>Survival</b>. This Section 9(d) and Sections 1, 2(c), 2(d),
              2(f), 3, 4, 5, 6, 7, 8, and 10 survive any termination or
              expiration of this Agreement. No other provisions of this
              Agreement survive the expiration or earlier termination of this
              Agreement.
            </li>
          </ol>
          <li>
            <b>Miscellaneous</b>.&nbsp;
          </li>
          <ol type="a">
            <li>
              <b>Entire Agreement</b>. This Agreement, together with the
              subscription page and any other documents incorporated herein by
              reference, constitutes the sole and entire agreement of the
              parties with respect to the subject matter of this Agreement and
              supersedes all prior and contemporaneous understandings,
              agreements, and representations and warranties, both written and
              oral, with respect to such subject matter. In the event of any
              inconsistency between the statements made in the body of this
              Agreement, the subscription page, and any other documents
              incorporated herein by reference, the following order of
              precedence governs: (i) first, this Agreement; (ii) second, the
              subscription page; and (iii) third, any other documents
              incorporated herein by reference.
            </li>
            <li>
              <b>Notices</b>. All notices, requests, consents, claims, demands,
              waivers, and other communications hereunder (each, a &ldquo;
              <u>Notice</u>&rdquo;) must be in writing and sent to the
              respective parties at the addresses set forth on the subscription
              page (or to such other address that may be designated by the party
              giving Notice from time to time in accordance with this Section).
              All Notices must be delivered by personal delivery, nationally
              recognized overnight courier (with all fees pre-paid), or
              certified or registered mail (in each case, return receipt
              requested, postage pre-paid). Except as otherwise provided in this
              Agreement, a Notice is effective only if the party giving the
              Notice has complied with the requirements of this Section.
            </li>
            <li>
              <b>Force Majeure</b>. PBJ will not be responsible or liable to
              you, or deemed in default or breach hereunder by reason of any
              failure or delay in the performance of its obligations hereunder
              where such failure or delay is due to strikes, labor disputes,
              civil disturbances, riot, rebellion, invasion, epidemic,
              hostilities, war, terrorist attack, embargo, natural disaster,
              acts of God, flood, fire, sabotage, fluctuations or
              non-availability of electrical power, heat, light, air
              conditioning, or your equipment, loss and destruction of property,
              or any other circumstances or causes beyond PBJ&rsquo;s reasonable
              control.
            </li>
            <li>
              <b>Amendment and Modification; Waiver</b>. No amendment to or
              modification of this Agreement is effective unless it is in
              writing and signed by each party hereto. No waiver by any party of
              any of the provisions hereof will be effective unless explicitly
              set forth in writing and signed by the party so waiving. Except as
              otherwise set forth in this Agreement, (i) no failure to exercise,
              or delay in exercising, any rights, remedy, power, or privilege
              arising from this Agreement will operate or be construed as a
              waiver thereof, and (ii) no single or partial exercise of any
              right, remedy, power, or privilege hereunder will preclude any
              other or further exercise thereof or the exercise of any other
              right, remedy, power, or privilege.
            </li>
            <li>
              <b>Severability</b>. If any provision of this Agreement is
              invalid, illegal, or unenforceable in any jurisdiction, such
              invalidity, illegality, or unenforceability will not affect any
              other term or provision of this Agreement or invalidate or render
              unenforceable such term or provision in any other jurisdiction.
              Upon such determination that any term or other provision is
              invalid, illegal, or unenforceable, the parties shall negotiate in
              good faith to modify this Agreement so as to effect their original
              intent as closely as possible in a mutually acceptable manner in
              order that the transactions contemplated hereby be consummated as
              originally contemplated to the greatest extent possible.
            </li>
            <li>
              <b>Governing Law; Submission to Jurisdiction</b>. This Agreement
              is governed by and construed in accordance with the internal laws
              of the State of New York without giving effect to any choice or
              conflict of law provision or rule that would require or permit the
              application of the laws of any jurisdiction other than those of
              the State of New York. Any legal suit, action, or proceeding
              arising out of or related to this Agreement or the licenses
              granted hereunder will be instituted exclusively in the federal
              courts of the United States or the courts of the State of New York
              in each case located in the city of New York, and each party
              irrevocably submits to the exclusive jurisdiction of such courts
              in any such suit, action, or proceeding.
            </li>
            <li>
              <b>Assignment</b>. You may not assign any of your rights or
              delegate any of your obligations hereunder, in each case whether
              voluntarily, involuntarily, by operation of law or otherwise,
              without the prior written consent of PBJ. Any purported assignment
              or delegation in violation of this Section will be null and void.
              No assignment or delegation will relieve the assigning or
              delegating party of any of its obligations hereunder. This
              Agreement is binding upon and inures to the benefit of the parties
              and their respective permitted successors and assigns.&nbsp;
            </li>
            <li>
              <b>Export Regulation</b>. You shall comply with all applicable
              federal laws, regulations, and rules, and complete all required
              undertakings (including obtaining any necessary export license or
              other governmental approval), that prohibit or restrict the export
              or re-export of the PBJ Live Platform or any Content outside the
              US.&nbsp;
            </li>
            <li>
              <b>Equitable Relief</b>. You acknowledge and agree that a breach
              or threatened breach by you of any of your obligations under
              Section 2(c) or Section 3(c) would cause PBJ irreparable harm for
              which monetary damages would not be an adequate remedy and you
              agree that, in the event of such breach or threatened breach, PBJ
              will be entitled to equitable relief, including a restraining
              order, an injunction, specific performance, and any other relief
              that may be available from any court, without any requirement to
              post a bond or other security, or to prove actual damages or that
              monetary damages are not an adequate remedy. Such remedies are not
              exclusive and are in addition to all other remedies that may be
              available at law, in equity, or otherwise.&nbsp;
            </li>
          </ol>
        </ol>
      </section>
    </div>
  </LayoutSimple>
)

export default Terms
